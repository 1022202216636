<template>
     <div>
          <div
               class="flex flex-col"
               :class="[
                    game_type == 'result' ? 'mt-5' : '-mt-2',
                    game_type == 'top' ? 'mb-5 md:mb-10' : 'mb-8 md:mb-14'
               ]"
          >
               <div class="flex flex-row items-center justify-between">
                    <div
                         v-if="
                              game_type == 'result'
                                   ? true
                                   : !floading && data?.data
                         "
                         class="flex flex-row items-center gap-2 text-white"
                         :class="{
                              'lobby-category-clickable cursor-pointer duration-300 ease-in-out hover:text-moozi-3':
                                   isLobby
                         }"
                         @click="isLobby ? onSetActive(game_type) : null"
                    >
                         <slot name="icon"></slot>
                         <p
                              class="font-roboto font-bold text-sm lg:text-lg capitalize"
                         >
                              {{ title }}
                         </p>
                    </div>
                    <Skeletor
                         v-else
                         as="div"
                         class="!w-32 lg:!w-44 !h-6 lg:!h-7 !rounded-md mx-1.5"
                    />
               </div>
               <div
                    v-if="
                         !floading && data?.data?.length != 0 && !loadingResult
                    "
                    class="mt-2"
               >
                    <div
                         class="grid grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 2xl:grid-cols-7 gap-3 mx-1.5 md:mx-0"
                    >
                         <div
                              v-for="item in data?.data"
                              :key="item.id"
                              :game-id="item.id"
                              @click="() => onRedirect(item)"
                         >
                              <div
                                   class="game-card rounded-xl w-full h-max relative md:mx-1.5 scale-100 hover:scale-105 transition-transform duration-300 ease-in-out"
                              >
                                   <div
                                        class="games-aspect-ratio-1-1 relative items-center cursor-pointer"
                                   >
                                        <div
                                             v-if="game_type == 'new'"
                                             class="absolute top-0 left-0 z-10 w-max h-max"
                                        >
                                             <div
                                                  class="bg-moozi-12 rounded-tl-md capitalize flex justify-center items-center px-3 md:px-4 py-0.5 md:py-1 font-roboto font-bold text-white text-sm lg:text-lg"
                                             >
                                                  new
                                             </div>
                                        </div>
                                        <img
                                             loading="lazy"
                                             :src="
                                                  baseUrl.img +
                                                  '/assets/img/loader/games-loader.png'
                                             "
                                             :alt="
                                                  getProvider(
                                                       item.provider_id
                                                  ) +
                                                  '-' +
                                                  item.game_pic
                                             "
                                             class="broken-image absolute top-0 left-0 rounded-md w-full h-full game-thumbnails"
                                             :class="
                                                  item.game_pic != null
                                                       ? 'invisible'
                                                       : ''
                                             "
                                        />
                                        <div
                                             class="absolute top-0 left-0 w-full h-full flex justify-center items-center"
                                        >
                                             <img
                                                  loading="lazy"
                                                  v-if="item.game_pic != null"
                                                  :src="item.game_pic"
                                                  :alt="
                                                       getProvider(
                                                            item.provider_id
                                                       ) +
                                                       '-' +
                                                       item.game_pic
                                                  "
                                                  class="thumbnail-image rounded-md w-full hidden md:block game-thumbnails"
                                                  style="height: inherit"
                                                  @error="onImageError"
                                             />
                                             <img
                                                  loading="lazy"
                                                  v-else
                                                  :src="
                                                       baseUrl.img +
                                                       '/assets/img/loader/games-loader.png'
                                                  "
                                                  :alt="
                                                       getProvider(
                                                            item.provider_id
                                                       ) +
                                                       '-' +
                                                       item.game_pic
                                                  "
                                                  class="thumbnail-image rounded-md w-full hidden md:block game-thumbnails"
                                                  style="height: inherit"
                                             />
                                             <img
                                                  loading="lazy"
                                                  v-if="
                                                       item.game_pic_mobile !=
                                                       null
                                                  "
                                                  :src="item.game_pic_mobile"
                                                  :alt="
                                                       getProvider(
                                                            item.provider_id
                                                       ) +
                                                       '-' +
                                                       item.game_pic
                                                  "
                                                  class="thumbnail-image rounded-md w-full h-auto block md:hidden game-thumbnails"
                                                  style="height: inherit"
                                                  @error="onImageError"
                                             />
                                             <img
                                                  loading="lazy"
                                                  v-else
                                                  :src="
                                                       baseUrl.img +
                                                       '/assets/img/loader/games-loader.png'
                                                  "
                                                  :alt="
                                                       getProvider(
                                                            item.provider_id
                                                       ) +
                                                       '-' +
                                                       item.game_pic
                                                  "
                                                  class="thumbnail-image rounded-md w-full h-auto block md:hidden game-thumbnails"
                                                  style="height: inherit"
                                             />
                                        </div>
                                   </div>
                              </div>
                         </div>
                         <div
                              v-for="key in 21"
                              :key="key"
                              v-if="loadingSeeMoreGames"
                         >
                              <div
                                   class="game-card rounded-xl w-full h-max relative md:mx-1.5 scale-100 transition-transform duration-300 ease-in-out"
                              >
                                   <div
                                        class="games-aspect-ratio-1-1 relative items-center cursor-pointer"
                                   >
                                        <img
                                             loading="lazy"
                                             :src="
                                                  baseUrl.img +
                                                  '/assets/img/loader/games-loader.png'
                                             "
                                             alt="game"
                                             class="absolute top-0 left-0 rounded-md w-full h-full game-thumbnails invisible"
                                        />
                                        <Skeletor
                                             as="div"
                                             class="!absolute !top-0 !left-0 !rounded-md !w-full !h-full"
                                        />
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
               <div
                    v-else-if="
                         data?.data?.length == 0 &&
                         !floading &&
                         loadingSeeMoreGames == null
                    "
                    class="mt-2"
               >
                    <div
                         class="bg-moozi-2 rounded-xl flex justify-center items-center font-roboto font-semibold text-sm lg:text-base text-moozi-4 py-5 md:py-10 px-4 md:px-10"
                    >
                         Sorry! No Games Available
                    </div>
               </div>
               <div
                    v-if="loadingResult"
                    class="mt-2"
               >
                    <div
                         class="grid grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 2xl:grid-cols-7 gap-3"
                    >
                         <div
                              v-for="key in 21"
                              :key="key"
                         >
                              <div
                                   class="game-card rounded-xl w-full h-max relative mx-1.5 scale-100 transition-transform duration-300 ease-in-out"
                              >
                                   <div
                                        class="games-aspect-ratio-1-1 relative items-center cursor-pointer"
                                   >
                                        <img
                                             loading="lazy"
                                             :src="
                                                  baseUrl.img +
                                                  '/assets/img/loader/games-preloader.png'
                                             "
                                             alt="game"
                                             class="absolute top-0 left-0 rounded-md w-full h-full game-thumbnails invisible"
                                        />
                                        <Skeletor
                                             as="div"
                                             class="!absolute !top-0 !left-0 !rounded-md !w-full !h-full"
                                        />
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
               <div
                    v-if="
                         data?.data?.length != 0 &&
                         pagination?.current < pagination?.last
                    "
                    class="flex justify-center items-center mt-7 lg:mt-14"
               >
                    <div
                         @click="onSeeMore()"
                         class="h-10 sm:h-11 flex flex-row items-center gap-1 rounded-full transition-transform duration-300 font-roboto font-bold tracking-wider px-14 bg-secondary-3 text-primary-1 text-sm sm:text-base md:text-lg lg:text-base xl:text-lg !cursor-pointer"
                    >
                         <span
                              class="cursor-pointer font-roboto text-sm lg:text-lg font-bold"
                              >See more</span
                         >
                    </div>
               </div>
          </div>
     </div>
</template>
<script>
import { computed, ref, onMounted } from "vue";
import { gamesStore } from "store/games";
import _ from "underscore";
import { useImage } from "composables/use-image.js";
import { baseUrl } from "config/services.js";
import { useRouter } from "vue-router";
import eventBus from "plugins/event.js";

export default {
     components: {},
     props: [
          "data",
          "title",
          "onSeeMore",
          "pagination",
          "game_type",
          "onSetActive",
          "isLobby"
     ],
     setup(props) {
          const useGamesStore = gamesStore();
          const router = useRouter();

          const { onImageError } = useImage();

          const floading = ref(false);

          const providerData = computed(() => {
               return useGamesStore.provider || null;
          });

          const loadingSeeMoreGames = computed(() => {
               return (
                    useGamesStore.loadingSeeMoreGames[props.game_type] || null
               );
          });

          const loadingResult = computed(() => {
               return useGamesStore.loadingResult || null;
          });

          const getProvider = (id) => {
               const filter = _.filter(providerData.value, (params) => {
                    return params["id"] == id;
               });

               return filter[0]["name"];
          };

          const onRedirect = (data) => {
               openGamePreview(data, () => {
                    router.push({
                         path:
                              "/games/detail/" +
                              encodeURIComponent(
                                   data.game_name.replaceAll(" ", "-")
                              ) +
                              "-" +
                              data.id
                    });
               });
          };

          const openGamePreview = (data, onPlay) => {
               eventBus.emit("open:gamepreview", {
                    data: data,
                    onPlay: onPlay
               });
          };

          onMounted(() => {
               if (providerData.value == null) {
                    floading.value = true;
                    setTimeout(() => {
                         floading.value = false;
                    }, 3000);
               }
          });

          return {
               baseUrl,
               onImageError,
               getProvider,
               floading,
               onRedirect,
               loadingSeeMoreGames,
               loadingResult,
               openGamePreview
          };
     }
};
</script>
