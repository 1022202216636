import { defineStore } from "pinia";
import {
     getAccount as getAccountAPI,
     getWallet as getWalletAPI
} from "api/connect_pay.js";
import _ from "underscore";

export const connectPayStore = defineStore("connectPay", {
     state: () => {
          return {
               enrolled: null,
               walletCount: 0,
               walletRedeem: null,
               walletRedeemCount: 0
          };
     },
     actions: {
          getEnrolled(force = false) {
               if (!force && this.enrolled != null) {
                    return;
               }
               return new Promise((resolve, reject) => {
                    getAccountAPI()
                         .then((response) => {
                              this.$patch((state) => {
                                   if (response?.enrolled) {
                                        state.enrolled =
                                             response?.enrolled?.response ?? [];
                                        const filter = _.filter(
                                             response?.enrolled?.response
                                                  .account,
                                             function (params) {
                                                  return (
                                                       params[
                                                            "accountStatus"
                                                       ] != "40"
                                                  );
                                             }
                                        );
                                        state.walletCount = filter.length;
                                   } else {
                                        state.enrolled = [];
                                        state.walletCount = 0;
                                   }
                              });
                              resolve();
                         })
                         .catch((error) => {
                              this.$patch((state) => {
                                   state.enrolled = [];
                                   state.walletCount = 0;
                              });
                              console.error("Error to load coins data!", error);
                              reject(error);
                         });
               });
          },
          getWalletRedeem(force = false) {
               if (!force && this.walletRedeem != null) {
                    return;
               }
               return new Promise((resolve, reject) => {
                    getWalletAPI()
                         .then((response) => {
                              this.$patch((state) => {
                                   if (response?.enrolled) {
                                        state.walletRedeem =
                                             response?.enrolled?.response ?? [];
                                        const filter = _.filter(
                                             response?.enrolled?.response
                                                  .account,
                                             function (params) {
                                                  return (
                                                       params[
                                                            "accountStatus"
                                                       ] != "40"
                                                  );
                                             }
                                        );
                                        state.walletRedeemCount = filter.length;
                                   } else {
                                        state.walletRedeem = [];
                                        state.walletRedeemCount = 0;
                                   }
                              });
                              resolve();
                         })
                         .catch((error) => {
                              this.$patch((state) => {
                                   state.walletRedeem = [];
                                   state.walletRedeemCount = 0;
                              });
                              console.error("Error to load coins data!", error);
                              reject(error);
                         });
               });
          }
     }
});
