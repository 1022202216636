<template>
     <Slider
          v-if="type == 'slider'"
          :data="games"
          title="Favorite games"
          :onNextSlide="onSeeMore"
          :pagination="pagination"
          :game_type="'favorite'"
          :onSetActive="onSetActive"
     >
          <template v-slot:icon>
               <svg
                    class="w-5"
                    viewBox="0 0 16 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
               >
                    <path
                         d="M14.5125 8.25738L8.86562 13.6515C8.63125 13.8753 8.32188 14 8 14C7.67813 14 7.36875 13.8753 7.13437 13.6515L1.4875 8.25738C0.5375 7.3525 0 6.08312 0 4.75618V4.57072C0 2.33571 1.57812 0.430028 3.73125 0.0623216C5.15625 -0.180684 6.60625 0.295735 7.625 1.3381L8 1.7218L8.375 1.3381C9.39375 0.295735 10.8438 -0.180684 12.2687 0.0623216C14.4219 0.430028 16 2.33571 16 4.57072V4.75618C16 6.08312 15.4625 7.3525 14.5125 8.25738Z"
                         fill="#F71111"
                    ></path>
               </svg>
          </template>
     </Slider>
     <Paginate
          v-else-if="type == 'paginate'"
          :data="games"
          title="Favorite games"
          :onSeeMore="onSeeMore"
          game_type="favorite"
     >
          <template v-slot:icon>
               <svg
                    class="w-5"
                    viewBox="0 0 16 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
               >
                    <path
                         d="M14.5125 8.25738L8.86562 13.6515C8.63125 13.8753 8.32188 14 8 14C7.67813 14 7.36875 13.8753 7.13437 13.6515L1.4875 8.25738C0.5375 7.3525 0 6.08312 0 4.75618V4.57072C0 2.33571 1.57812 0.430028 3.73125 0.0623216C5.15625 -0.180684 6.60625 0.295735 7.625 1.3381L8 1.7218L8.375 1.3381C9.39375 0.295735 10.8438 -0.180684 12.2687 0.0623216C14.4219 0.430028 16 2.33571 16 4.57072V4.75618C16 6.08312 15.4625 7.3525 14.5125 8.25738Z"
                         fill="#F71111"
                    ></path>
               </svg>
          </template>
     </Paginate>
</template>
<script>
import { computed, reactive, watch } from "vue";
import { gamesStore } from "store/games";
import Slider from "components/games/slider/index.vue";
import Paginate from "components/games/paginate/index.vue";

export default {
     components: {
          Slider,
          Paginate
     },
     props: ["type", "onSetActive"],
     setup() {
          const useGamesStore = gamesStore();

          const games = reactive({
               data: null
          });

          const pagination = reactive({
               current: null,
               last: null,
               next: null
          });

          const favoriteData = computed(() => {
               if (useGamesStore.allFavorites) {
                    games.data = useGamesStore.allFavorites["data"];
                    pagination["current"] =
                         useGamesStore.allFavorites["current_page"];
                    pagination["last"] =
                         useGamesStore.allFavorites["last_page"];
                    const temp = useGamesStore.allFavorites["next_page_url"];
                    pagination["next"] =
                         temp != null ? temp.slice(-1) : pagination["last"];
               }
               return useGamesStore.allFavorites || null;
          });

          const onSeeMore = async () => {
               const data = {
                    game_name: "",
                    game_promo: 0,
                    game_type: [],
                    provider_id: params.provider_id,
                    page: pagination["next"],
                    itemsPerPage: 21,
                    sortType: params.sortType
               };

               await useGamesStore.getFavorites(data, false, true);
          };

          watch(favoriteData, (newVal) => {
               if (newVal) {
                    games.data = newVal["data"];
                    pagination["current"] = newVal["current_page"];
                    pagination["last"] = newVal["last_page"];
                    const temp = newVal["next_page_url"];
                    pagination["next"] =
                         temp != null ? temp.slice(-1) : pagination["last"];
               }
          });

          return {
               games,
               onSeeMore,
               pagination
          };
     }
};
</script>
