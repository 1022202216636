export const useGTag = () => {
     const item_list_id = "gold_coin_package";
     const item_list_name = "Gold Coin Package";
     const currency = "USD";

     const gTagViewItemList = (items) => {
          gtag("event", "view_item_list", {
               item_list_id: item_list_id,
               item_list_name: item_list_name,
               items: items
          });
     };

     const gTagSelectItemList = (item) => {
          gtag("event", "select_item", {
               item_list_id: item_list_id,
               item_list_name: item_list_name,
               items: [item]
          });
     };

     const gTagBeginCheckout = (value, item) => {
          gtag("event", "begin_checkout", {
               currency: currency,
               value: value,
               items: [item]
          });
     };

     const gTagAddPaymentInfo = (value, payment_type, item) => {
          gtag("event", "add_payment_info", {
               currency: currency,
               value: value,
               payment_type: payment_type,
               items: [item]
          });
     };

     const gTagPurchase = (transaction_id, value, item) => {
          gtag("event", "purchase", {
               transaction_id: transaction_id,
               currency: currency,
               value: value,
               items: [item]
          });
     };

     return {
          gTagViewItemList,
          gTagSelectItemList,
          gTagBeginCheckout,
          gTagAddPaymentInfo,
          gTagPurchase
     };
};
