import { authStore } from "store/auth.js";
import { computed } from "vue";
import Cookies from "js-cookie";
import { resetReminder as resetReminderAPI } from "api/auth.js";
import { bonusStore } from "store/bonus.js";
import eventBus from "plugins/event.js";
import { ioSocketStore } from "store/io.socket.js";
import { getGameToken as getGameTokenAPI } from "@/api/auth";
import { gamesStore } from "store/games";
import { useRoute, useRouter } from "vue-router";
import { useToaster } from "composables/use-toaster.js";

export const useIOSocketInit = () => {
     const route = useRoute();
     const router = useRouter();

     const useAuthStore = authStore();
     const useBonusStore = bonusStore();
     const useIOSocketStore = ioSocketStore();
     const useGamesStore = gamesStore();

     const { errorToast } = useToaster();

     const username = computed(() => {
          return useAuthStore.username;
     });

     const socket = computed(() => {
          return useIOSocketStore.socket;
     });

     const key =
          import.meta.env.VITE_ENV == "production"
               ? "production:customer_action"
               : "development:customer_action";

     const onWatchResponseReceivedIO = () => {
          if (socket.value) {
               socket.value.on(key, async function (data) {
                    const response = JSON.parse(data);

                    if (response.action == "check-auth") {
                         useAuthStore.getUser(true);
                    } else if (response.action == "email_verified") {
                         location.reload();
                    } else if (response.action == "login") {
                         eventBus.emit("open:session_expired");
                    } else if (response.action == "logout_reminder") {
                         resetReminderAPI();
                         eventBus.emit("open:reminder");
                    } else if (response.action == "free_gc_topup") {
                         useBonusStore.claimFreeGC();
                    } else if (response.action == "silver_winnings") {
                         useAuthStore.setSilverRedeemable(response.balance);
                    } else if (response.action == "game_disabled") {
                         if (route.name == "play") {
                              errorToast(
                                   "Sorry! This game is not available at the moment.",
                                   "top-right"
                              );
                              router.push({ name: "home" });
                         }
                    } else {
                         useAuthStore.updateWalletBalance(response);
                    }
               });
          }
     };

     const emitGameOpenIO = () => {
          if (socket.value) {
               socket.value.emit(
                    "game:open",
                    "user." +
                         (username.value
                              ? username.value
                              : Cookies.get("username"))
               );
               getGameToken();
          }
     };

     const emitGameCloseIO = () => {
          if (socket.value) {
               socket.value.emit(
                    "game:close",
                    "user." +
                         (username.value
                              ? username.value
                              : Cookies.get("username"))
               );
          }
     };

     const destroySocketIO = () => {
          if (socket.value) {
               socket.value.off("customer_action");
          }
     };

     const onWatchGetGameIO = () => {
          if (socket.value) {
               socket.value.on("game:get", async function () {
                    if (route.params.launchcode) {
                         await getGameToken();
                    }
               });
          }
     };

     const getGameToken = async () => {
          await getGameTokenAPI({ launch_code: route.params.launchcode })
               .then((res) => {
                    if (!res.status) {
                         useGamesStore.setGameToken(true);
                    } else {
                         useGamesStore.setGameToken(false);
                         useAuthStore.setActiveGameUrl(res.launch.url);
                    }
               })
               .catch((error) => {
                    console.error("Error to get Game Token data!", error);
                    useGamesStore.setGameToken(false);
               });
     };

     return {
          onWatchResponseReceivedIO,
          emitGameOpenIO,
          emitGameCloseIO,
          destroySocketIO,
          onWatchGetGameIO
     };
};
