<template>
     <Paginate
          :data="games"
          title="All Games"
          :onSeeMore="onSeeMore"
          :pagination="pagination"
          game_type="all"
          :onSetActive="onSetActive"
          :isLobby="isLobby"
     >
          <template v-slot:icon>
               <svg
                    class="w-7"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
               >
                    <path
                         d="M12.129 18.1626C10.8245 18.1626 9.5199 18.1626 8.21534 18.1626C7.11148 18.1626 6.04776 17.9418 5.0844 17.3799C3.77984 16.6172 2.83654 15.5334 2.35486 14.0883C1.77282 12.3623 1.89324 10.6563 2.81647 9.07076C3.75977 7.42499 5.18474 6.42148 7.07134 6.10035C7.53295 6.02007 7.99457 6 8.45618 6C10.985 6 13.5139 6 16.0427 6C16.9258 6 17.7888 6.14049 18.6117 6.50176C20.2374 7.20422 21.3613 8.40844 21.9433 10.0743C22.5454 11.8204 22.3849 13.5063 21.4817 15.1119C20.5384 16.7778 19.0934 17.7612 17.2268 18.0623C16.7652 18.1425 16.3036 18.1626 15.842 18.1626C14.5976 18.1626 13.3734 18.1626 12.129 18.1626ZM12.129 16.6573C13.4336 16.6573 14.7181 16.6573 16.0226 16.6573C16.3839 16.6573 16.7652 16.6172 17.1265 16.557C19.8159 16.0151 21.3211 13.3457 20.5786 10.8169C19.9965 8.82991 18.2705 7.54541 16.1832 7.52534C13.4938 7.52534 10.7843 7.52534 8.09492 7.52534C7.01113 7.52534 6.00763 7.86654 5.16468 8.569C3.96047 9.57251 3.41858 10.8972 3.51893 12.4426C3.59921 13.6267 4.08089 14.6503 4.96397 15.4531C5.86713 16.276 6.95092 16.6573 8.15513 16.6573C9.47976 16.6573 10.8044 16.6573 12.1089 16.6573H12.129Z"
                         fill="white"
                    ></path>
                    <path
                         d="M7.85413 11.3182C7.85413 10.9368 7.83406 10.5956 7.85413 10.2544C7.89427 9.71254 8.39602 9.43155 8.8777 9.63226C9.19883 9.77275 9.31925 10.0337 9.31925 10.3548C9.31925 10.6759 9.31925 10.977 9.31925 11.3182C9.64037 11.3182 9.94142 11.3182 10.2425 11.3182C10.5435 11.3182 10.8044 11.3984 10.9851 11.6794C11.2861 12.2213 10.9249 12.8034 10.2826 12.8234C9.98156 12.8234 9.68051 12.8234 9.33932 12.8234C9.33932 13.1446 9.33932 13.4456 9.33932 13.7467C9.33932 14.0277 9.27911 14.2886 9.0182 14.4491C8.53651 14.7703 7.91434 14.4893 7.85413 13.9072C7.81399 13.566 7.85413 13.2048 7.85413 12.8034C7.51294 12.8034 7.19181 12.8034 6.89076 12.8034C6.24851 12.8034 5.86718 12.2013 6.1883 11.6594C6.34887 11.3784 6.60977 11.2981 6.89076 11.2981C7.19181 11.2981 7.49287 11.2981 7.85413 11.2981V11.3182Z"
                         fill="white"
                    ></path>
                    <path
                         d="M15.7214 10.5357C15.7214 9.9537 16.1429 9.5523 16.7049 9.53223C17.2668 9.53223 17.7084 9.97377 17.7084 10.5357C17.7084 11.1178 17.2869 11.5393 16.7049 11.5393C16.1228 11.5393 15.7214 11.1178 15.7214 10.5157V10.5357Z"
                         fill="white"
                    ></path>
                    <path
                         d="M13.6945 13.607C13.6945 13.025 14.1159 12.6035 14.698 12.6035C15.2599 12.6035 15.6814 13.025 15.6814 13.607C15.6814 14.1891 15.2599 14.6306 14.698 14.6306C14.1159 14.6306 13.6945 14.2091 13.6945 13.6271V13.607Z"
                         fill="white"
                    ></path>
               </svg>
          </template>
     </Paginate>
</template>
<script>
import { computed, reactive, watch } from "vue";
import { gamesStore } from "store/games";
import Paginate from "components/games/paginate/index.vue";

export default {
     components: {
          Paginate
     },
     props: ["onSetActive", "active"],
     setup() {
          const useGamesStore = gamesStore();

          const games = reactive({
               data: null
          });

          const params = reactive({});

          const pagination = reactive({
               current: null,
               last: null,
               next: null
          });

          const gamesData = computed(() => {
               if (useGamesStore.allGames["all"]) {
                    games.data = useGamesStore.allGames["all"]["data"];
                    pagination["current"] =
                         useGamesStore.allGames["all"]["current_page"];
                    pagination["last"] =
                         useGamesStore.allGames["all"]["last_page"];
                    const temp = useGamesStore.allGames["all"]["next_page_url"];
                    pagination["next"] =
                         temp != null ? temp.slice(-1) : pagination["last"];
               }
               return useGamesStore.allGames["all"] || null;
          });

          const gameFilter = computed(() => {
               const obj = Object.keys(useGamesStore.gameFilter);
               for (let index = 0; index < obj.length; index++) {
                    params[obj[index]] = useGamesStore.gameFilter[obj[index]];
               }

               return useGamesStore.gameFilter;
          });

          const onSeeMore = async () => {
               const data = {
                    game_name: "",
                    game_promo: 0,
                    game_type: [],
                    provider_id: params.provider_id,
                    page: pagination["next"],
                    itemsPerPage: 48,
                    sortType: params.sortType
               };

               await useGamesStore.getGames(data, "all", false, true);
          };

          watch(gamesData, (newVal) => {
               if (newVal) {
                    if (newVal?.["all"]) {
                         games.data = newVal["all"]["data"];
                         pagination["current"] = newVal["all"]["current_page"];
                         pagination["last"] = newVal["all"]["last_page"];
                         const temp = newVal["all"]["next_page_url"];
                         pagination["next"] =
                              temp != null
                                   ? temp.slice(-1)
                                   : pagination["last"];
                    }
               }
          });

          watch(gameFilter, () => {});

          return {
               games,
               onSeeMore,
               pagination
          };
     }
};
</script>
