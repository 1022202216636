<template>
     <div
          class="rounded-full bg-moozi-2 w-full h-full flex flex-row items-center border border-solid border-moozi-4"
     >
          <span class="icon-search text-3xl ml-2 md:ml-3 !text-white"></span>
          <input
               type="text"
               class="search-game rounded-full p-0 pl-1 md:pl-2 h-full !bg-moozi-2 font-roboto font-medium !text-white text-sm lg:text-base outline-none border-none !shadow-none focus:shadow-none"
               @keyup="() => onSearch(search)"
               placeholder="Search"
               v-model="search"
               style="width: inherit"
          />
          <div
               v-if="search != ''"
               class="px-2 cursor-pointer"
               @click="onClear(), (search = '')"
          >
               <svg
                    class="w-5 xl:w-6"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
               >
                    <path
                         d="M9 8L15 16M15 8L9 16M12 21C14.3869 21 16.6761 20.0518 18.364 18.364C20.0518 16.6761 21 14.3869 21 12C21 9.61305 20.0518 7.32387 18.364 5.63604C16.6761 3.94821 14.3869 3 12 3C9.61305 3 7.32387 3.94821 5.63604 5.63604C3.94821 7.32387 3 9.61305 3 12C3 14.3869 3.94821 16.6761 5.63604 18.364C7.32387 20.0518 9.61305 21 12 21Z"
                         stroke="white"
                         stroke-width="2"
                         stroke-linecap="round"
                         stroke-linejoin="round"
                    />
               </svg>
          </div>
     </div>
</template>
<script>
import { ref } from "vue";

export default {
     props: ["onSearch", "onClear"],
     setup() {
          const search = ref("");
          return {
               search
          };
     }
};
</script>
