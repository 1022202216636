<template>
     <div class="w-full">
          <Carousel
               v-if="banner && !loading"
               :itemsToShow="2"
               :itemsToScroll="2"
               snapAlign="start"
               :touchDrag="true"
               :mouseDrag="true"
               :transition="1300"
               key="games-banner-carousel"
               class="hidden lg:block w-full games-banner-carousel"
               :autoplay="3000"
               :wrapAround="true"
          >
               <Slide
                    v-for="(slide, key) in banner"
                    :key="key"
               >
                    <div class="carousel__item w-full cursor-pointer mx-3">
                         <img
                              @click="gotoPromotion(slide.url)"
                              :src="baseUrl.img + '' + slide.banner"
                              alt=""
                              srcset=""
                              class="w-full h-auto cursor-pointer rounded-lg lg:rounded-xl"
                         />
                    </div>
               </Slide>
          </Carousel>

          <div
               v-else
               class="carousel__item mx-3 hidden lg:flex flex-row gap-4"
          >
               <div class="w-1/2 cursor-pointer relative h-max">
                    <img
                         @click="gotoPromotion(slide)"
                         :src="
                              baseUrl.img +
                              '/assets/img/loader/banner-medium-preloader.png'
                         "
                         alt=""
                         srcset=""
                         class="w-full h-auto cursor-pointer rounded-lg lg:rounded-xl invisible"
                    />
                    <Skeletor
                         as="div"
                         class="!absolute top-0 !w-full/12 !h-full !rounded-lg lg:!rounded-xl"
                    />
               </div>
               <div class="w-1/2 cursor-pointer relative h-max">
                    <img
                         @click="gotoPromotion(slide)"
                         :src="
                              baseUrl.img +
                              '/assets/img/loader/banner-medium-preloader.png'
                         "
                         alt=""
                         srcset=""
                         class="w-full h-auto cursor-pointer rounded-lg lg:rounded-xl invisible"
                    />
                    <Skeletor
                         as="div"
                         class="!absolute top-0 !w-full/12 !h-full !rounded-lg lg:!rounded-xl"
                    />
               </div>
          </div>
          <Carousel
               v-if="banner && !loading"
               :itemsToShow="1"
               :itemsToScroll="1"
               snapAlign="start"
               :touchDrag="true"
               :mouseDrag="true"
               :transition="1000"
               key="games-banner-carousel"
               class="block lg:hidden w-full games-banner-carousel"
               :autoplay="2000"
               :wrapAround="true"
          >
               <Slide
                    v-for="(slide, key) in banner"
                    :key="key"
               >
                    <div class="carousel__item w-full cursor-pointer mx-3">
                         <img
                              @click="gotoPromotion(slide)"
                              :src="baseUrl.img + '' + slide.banner_mobile"
                              alt=""
                              srcset=""
                              class="w-full h-auto cursor-pointer rounded-lg lg:rounded-xl"
                         />
                    </div>
               </Slide>
          </Carousel>
          <div
               v-else
               class="carousel__item w-full cursor-pointer mx-3 relative h-max block lg:hidden"
          >
               <img
                    @click="gotoPromotion(slide)"
                    :src="
                         baseUrl.img + '/assets/img/loader/banner-preloader.png'
                    "
                    alt=""
                    srcset=""
                    class="w-full h-auto cursor-pointer rounded-lg lg:rounded-xl invisible"
               />
               <Skeletor
                    as="div"
                    class="!absolute top-0 !w-full !h-full !rounded-lg lg:!rounded-xl"
               />
          </div>
     </div>
</template>
<script>
import { ref, computed, watch } from "vue";
import { promoStore } from "store/promotion";
import { baseUrl } from "config/services.js";
import { Carousel, Slide } from "vue3-carousel";

export default {
     components: {
          Carousel,
          Slide
     },
     setup() {
          const banner = ref(null);

          const loading = ref(false);

          const usePromoStore = promoStore();

          const bannerData = computed(() => {
               if (usePromoStore.banner) {
                    banner.value = usePromoStore.banner;
               }
               return usePromoStore.banner || null;
          });

          const gotoPromotion = (url) => {
               if (url == "" || url == null) {
                    return;
               }
               window.open(url, "_blank");
          };

          const modifyImagePath = (path, isMobile = false) => {
               const img = path.split(".");
               return img[0] + (isMobile ? "-medium." : ".") + img[1];
          };

          watch(bannerData, (newVal) => {
               if (newVal) {
                    loading.value = true;
                    banner.value = newVal;
                    setTimeout(() => {
                         loading.value = false;
                    }, 1500);
               }
          });

          return {
               banner,
               baseUrl,
               gotoPromotion,
               modifyImagePath,
               loading
          };
     }
};
</script>
