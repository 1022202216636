<template>
     <InertiaHead title="404"></InertiaHead>
     <div class="absolute top-0 bg-gray-100 w-full">
          <div class="h-screen flex flex-col justify-center items-center">
               <h1 class="text-8xl font-bold text-gray-800">404</h1>
               <p class="text-4xl font-medium text-gray-800">Page Not Found</p>
               <a
                    href="/"
                    class="mt-4 text-xl text-blue-600 hover:underline"
                    >Go to home page</a
               >
          </div>
     </div>
</template>

<script>
import { Head as InertiaHead } from "@inertiajs/vue3";

export default {
     components: {
          InertiaHead
     },
     setup() {
          return {};
     }
};
</script>
