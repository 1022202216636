<template>
     <div>
          <div
               class="space-y-4 font-roboto text-sm xs:text-base sm:text-lg md:text-xl lg:text-xl xl:text-2xl"
          >
               <div class="w-full h-full">
                    <div class="divide-y divide-shade-12 w-full">
                         <div class="flex items-center pb-2">
                              <div
                                   class="flex-1 flex flex-col items-start gap-1 mb-2"
                              >
                                   <span
                                        class="font-bold text-moozi-1 leading-tight text-base lg:text-lg font-roboto"
                                   >
                                        Session Reminder
                                   </span>
                                   <span
                                        class="text-shade-7 font-normal text-xs lg:text-sm"
                                   >
                                        Turn on for reminder to log off.
                                   </span>
                              </div>
                              <div>
                                   <label
                                        class="flex cursor-pointer select-none items-center"
                                   >
                                        <div class="relative">
                                             <input
                                                  type="checkbox"
                                                  class="sr-only"
                                                  :disabled="responsibleLoading"
                                                  @change="
                                                       handleCheckboxChange(
                                                            'reminder'
                                                       )
                                                  "
                                             />
                                             <div
                                                  class="block h-7 w-14 rounded-full shadow-inner"
                                                  :class="
                                                       isCheckedReminder
                                                            ? 'bg-background-3'
                                                            : 'bg-background-5'
                                                  "
                                             ></div>
                                             <div
                                                  :class="{
                                                       'translate-x-full':
                                                            isCheckedReminder
                                                  }"
                                                  class="shadow-lg dot absolute left-c top-xc h-4.5 w-4.5 rounded-full bg-white transform ease-in-out transition-all duration-300"
                                             ></div>
                                        </div>
                                   </label>
                              </div>
                         </div>
                         <div class="flex items-center mb-2 pt-6 md:pt-8">
                              <div
                                   class="flex-1 flex flex-col items-start gap-1 mb-2"
                              >
                                   <span
                                        class="font-bold text-moozi-1 leading-tight text-base lg:text-lg font-roboto"
                                   >
                                        Self Exclude
                                   </span>
                                   <span
                                        class="text-shade-7 font-normal text-xs xs:text-xs sm:text-xs md:text-sm lg:text-sm"
                                   >
                                        Turn on to block game play according
                                        your selected duration.
                                   </span>
                              </div>
                              <div>
                                   <label
                                        class="flex cursor-pointer select-none items-center"
                                   >
                                        <div class="relative">
                                             <input
                                                  type="checkbox"
                                                  class="sr-only"
                                                  :disabled="responsibleLoading"
                                                  @change="
                                                       handleCheckboxChange(
                                                            'self-exclude'
                                                       )
                                                  "
                                                  :class="{
                                                       'cursor-not-allowed':
                                                            !allowToExclude
                                                  }"
                                             />
                                             <div
                                                  class="block h-7 w-14 rounded-full shadow-inner"
                                                  :class="[
                                                       isCheckedSelfExclude
                                                            ? 'bg-background-3'
                                                            : 'bg-background-5',
                                                       {
                                                            'cursor-not-allowed':
                                                                 !allowToExclude
                                                       }
                                                  ]"
                                             ></div>
                                             <div
                                                  :class="[
                                                       {
                                                            'translate-x-full':
                                                                 isCheckedSelfExclude
                                                       },
                                                       {
                                                            'cursor-not-allowed':
                                                                 !allowToExclude
                                                       }
                                                  ]"
                                                  class="shadow-lg dot absolute left-c top-xc h-4.5 w-4.5 rounded-full bg-white transform ease-in-out transition-all duration-300"
                                             ></div>
                                        </div>
                                   </label>
                              </div>
                         </div>
                         <div
                              v-if="!allowToExclude"
                              class="px-2 lg:px-4 py-2 lg:py-4"
                         >
                              <div
                                   class="w-full px-5 py-3 bg-background-3 text-white rounded-md flex flex-row items-center gap-3"
                              >
                                   <svg
                                        class="w-5 h-5"
                                        viewBox="0 0 12 11"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                   >
                                        <path
                                             fill-rule="evenodd"
                                             clip-rule="evenodd"
                                             d="M0.153916 8.44707L5.01799 0.538469C5.12037 0.374093 5.26451 0.238188 5.43652 0.143869C5.60853 0.0495501 5.8026 0 6 0C6.19739 0 6.39146 0.0495501 6.56347 0.143869C6.73548 0.238188 6.87962 0.374093 6.982 0.538469L11.8461 8.44707C11.9464 8.61622 11.9994 8.80799 12 9.00331C12.0005 9.19863 11.9486 9.39069 11.8493 9.56039C11.75 9.73008 11.6068 9.87148 11.4339 9.97054C11.2611 10.0696 11.0646 10.1228 10.8641 10.125H1.13592C0.935383 10.1228 0.73892 10.0696 0.566074 9.97054C0.393229 9.87148 0.250027 9.73008 0.150715 9.56039C0.0514029 9.39069 -0.000557029 9.19863 4.50351e-06 9.00331C0.000566036 8.80799 0.0536294 8.61622 0.153916 8.44707ZM5.24998 3C5.24998 2.58578 5.58577 2.25 5.99998 2.25C6.4142 2.25 6.74998 2.58578 6.74998 3V6C6.74998 6.41421 6.4142 6.74999 5.99998 6.74999C5.58577 6.74999 5.24998 6.41421 5.24998 6V3ZM5.24998 8.24999C5.24998 7.83578 5.58577 7.49999 5.99998 7.49999C6.41419 7.49999 6.74998 7.83578 6.74998 8.24999C6.74998 8.66421 6.41419 8.99999 5.99998 8.99999C5.58577 8.99999 5.24998 8.66421 5.24998 8.24999Z"
                                             fill="#FFF"
                                        />
                                   </svg>
                                   <p
                                        class="w-full font-roboto text-sm font-medium tracking-wider"
                                   >
                                        Please redeem your redeemable SC holding
                                        to complete the self exclusion process
                                        (minimum redemption of 100 redeemable SC
                                        still applies)
                                   </p>
                              </div>
                              <div class="flex justify-end w-full mt-2">
                                   <p
                                        @click="goToRedeem"
                                        class="text-background-3 cursor-pointer font-helveticaLight tracking-wide font-normal text-xs xs:text-xs sm:text-xs md:text-sm lg:text-sm underline underline-offset-2 mb-2"
                                   >
                                        Go to redeem
                                   </p>
                              </div>
                         </div>
                         <div
                              v-if="isCheckedSelfExclude"
                              class="flex flex-col items-start mb-2 pt-4 px-4"
                         >
                              <div
                                   class="flex-1 flex flex-col items-start gap-1"
                              >
                                   <span
                                        class="font-bold text-moozi-1 leading-tight text-sm lg:text-base font-roboto"
                                   >
                                        Duration
                                   </span>
                                   <span
                                        class="text-shade-7 font-normal text-xs lg:text-sm"
                                   >
                                        Select duration below.
                                   </span>
                              </div>
                              <div class="w-full">
                                   <ul
                                        class="items-center w-full text-sm font-medium text-black text-left rounded-lg sm:flex gap-3"
                                   >
                                        <li class="w-full">
                                             <div class="flex items-center">
                                                  <input
                                                       id="exclude-duration-a"
                                                       type="radio"
                                                       v-model="
                                                            durationExcludeVal
                                                       "
                                                       @change="
                                                            onChangeExcludeDuration
                                                       "
                                                       value="1"
                                                       name="list-duration"
                                                       class="w-4 h-4 text-background-3 focus:ring-background-3 bg-white border-shade-18"
                                                  />
                                                  <label
                                                       for="exclude-duration-a"
                                                       class="w-full ms-3 leading-tight text-sm font-medium text-black text-left py-3"
                                                       >1 day</label
                                                  >
                                             </div>
                                        </li>
                                        <li class="w-full">
                                             <div class="flex items-center">
                                                  <input
                                                       id="exclude-duration-b"
                                                       type="radio"
                                                       v-model="
                                                            durationExcludeVal
                                                       "
                                                       @change="
                                                            onChangeExcludeDuration
                                                       "
                                                       value="7"
                                                       name="list-duration"
                                                       class="w-4 h-4 text-background-3 focus:ring-background-3 bg-white border-shade-18"
                                                  />
                                                  <label
                                                       for="exclude-duration-b"
                                                       class="w-full ms-3 leading-tight text-sm font-medium text-black text-left py-3"
                                                       >7 days</label
                                                  >
                                             </div>
                                        </li>
                                        <li class="w-full">
                                             <div class="flex items-center">
                                                  <input
                                                       id="exclude-duration-c"
                                                       type="radio"
                                                       v-model="
                                                            durationExcludeVal
                                                       "
                                                       @change="
                                                            onChangeExcludeDuration
                                                       "
                                                       value="28"
                                                       name="list-duration"
                                                       class="w-4 h-4 text-background-3 focus:ring-background-3 bg-white border-shade-18"
                                                  />
                                                  <label
                                                       for="exclude-duration-c"
                                                       class="w-full ms-3 leading-tight text-sm font-medium text-black text-left py-3"
                                                       >28 days</label
                                                  >
                                             </div>
                                        </li>
                                        <li class="w-full">
                                             <div class="flex items-center">
                                                  <input
                                                       id="exclude-duration-d"
                                                       type="radio"
                                                       v-model="
                                                            durationExcludeVal
                                                       "
                                                       @change="
                                                            onChangeExcludeDuration
                                                       "
                                                       value="90"
                                                       name="list-duration"
                                                       class="w-4 h-4 text-background-3 focus:ring-background-3 bg-white border-shade-18"
                                                  />
                                                  <label
                                                       for="exclude-duration-d"
                                                       class="w-full ms-3 leading-tight text-sm font-medium text-black text-left py-3"
                                                       >90 days</label
                                                  >
                                             </div>
                                        </li>
                                   </ul>
                              </div>
                         </div>
                         <div class="flex items-center mb-2 pt-6 md:pt-8">
                              <div
                                   class="flex-1 flex flex-col items-start gap-1 mb-2"
                              >
                                   <span
                                        class="font-bold text-moozi-1 leading-tight text-base lg:text-lg font-roboto"
                                   >
                                        Daily GC purchase limit
                                   </span>
                                   <span
                                        class="text-shade-7 font-normal text-xs lg:text-sm"
                                   >
                                        Turn on and set amount limit and
                                        duration of limit.
                                   </span>
                              </div>
                              <div>
                                   <label
                                        class="flex cursor-pointer select-none items-center"
                                   >
                                        <div class="relative">
                                             <input
                                                  type="checkbox"
                                                  class="sr-only"
                                                  :disabled="responsibleLoading"
                                                  @change="
                                                       handleCheckboxChange(
                                                            'daily'
                                                       )
                                                  "
                                             />
                                             <div
                                                  class="block h-7 w-14 rounded-full shadow-inner"
                                                  :class="
                                                       isCheckedDailyPurchaseLimit
                                                            ? 'bg-background-3'
                                                            : 'bg-background-5'
                                                  "
                                             ></div>
                                             <div
                                                  :class="{
                                                       'translate-x-full':
                                                            isCheckedDailyPurchaseLimit
                                                  }"
                                                  class="shadow-lg dot absolute left-c top-xc h-4.5 w-4.5 rounded-full bg-white transform ease-in-out transition-all duration-300"
                                             ></div>
                                        </div>
                                   </label>
                              </div>
                         </div>
                         <div
                              v-if="isCheckedDailyPurchaseLimit"
                              class="px-2 lg:px-4 py-2 lg:py-4"
                         >
                              <div
                                   class="w-full px-5 py-3 bg-background-3 text-white rounded-md flex flex-row items-center gap-3"
                              >
                                   <svg
                                        class="w-5 h-5"
                                        viewBox="0 0 12 11"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                   >
                                        <path
                                             fill-rule="evenodd"
                                             clip-rule="evenodd"
                                             d="M0.153916 8.44707L5.01799 0.538469C5.12037 0.374093 5.26451 0.238188 5.43652 0.143869C5.60853 0.0495501 5.8026 0 6 0C6.19739 0 6.39146 0.0495501 6.56347 0.143869C6.73548 0.238188 6.87962 0.374093 6.982 0.538469L11.8461 8.44707C11.9464 8.61622 11.9994 8.80799 12 9.00331C12.0005 9.19863 11.9486 9.39069 11.8493 9.56039C11.75 9.73008 11.6068 9.87148 11.4339 9.97054C11.2611 10.0696 11.0646 10.1228 10.8641 10.125H1.13592C0.935383 10.1228 0.73892 10.0696 0.566074 9.97054C0.393229 9.87148 0.250027 9.73008 0.150715 9.56039C0.0514029 9.39069 -0.000557029 9.19863 4.50351e-06 9.00331C0.000566036 8.80799 0.0536294 8.61622 0.153916 8.44707ZM5.24998 3C5.24998 2.58578 5.58577 2.25 5.99998 2.25C6.4142 2.25 6.74998 2.58578 6.74998 3V6C6.74998 6.41421 6.4142 6.74999 5.99998 6.74999C5.58577 6.74999 5.24998 6.41421 5.24998 6V3ZM5.24998 8.24999C5.24998 7.83578 5.58577 7.49999 5.99998 7.49999C6.41419 7.49999 6.74998 7.83578 6.74998 8.24999C6.74998 8.66421 6.41419 8.99999 5.99998 8.99999C5.58577 8.99999 5.24998 8.66421 5.24998 8.24999Z"
                                             fill="#FFF"
                                        />
                                   </svg>
                                   <p
                                        class="w-full font-roboto text-sm font-medium tracking-wider"
                                   >
                                        You have set to limit your daily
                                        purchase limit to ${{
                                             amountDailyVal
                                        }}
                                        till {{ lastDateVal }}.
                                   </p>
                              </div>
                         </div>
                         <div
                              v-if="isCheckedDailyPurchaseLimit"
                              class="flex flex-col items-start mb-2 pt-4 px-4"
                         >
                              <div
                                   class="flex-1 flex flex-col items-start gap-1"
                              >
                                   <span
                                        class="font-bold text-moozi-1 leading-tight text-sm lg:text-base font-roboto"
                                   >
                                        Amount
                                   </span>
                                   <span
                                        class="text-shade-7 font-normal text-xs lg:text-sm"
                                   >
                                        Select amount limit below.
                                   </span>
                              </div>
                              <div class="w-full">
                                   <ul
                                        class="items-center w-full text-sm font-medium text-black text-left rounded-lg sm:flex gap-4"
                                   >
                                        <li class="w-full">
                                             <div class="flex items-center">
                                                  <input
                                                       checked
                                                       id="daily-amount-a"
                                                       type="radio"
                                                       v-model="amountDailyVal"
                                                       @change="
                                                            onChangeDailyAmount
                                                       "
                                                       value="50"
                                                       name="list-amount"
                                                       class="w-4 h-4 text-background-3 focus:ring-background-3 bg-white border-shade-18"
                                                  />
                                                  <label
                                                       for="daily-amount-a"
                                                       class="w-full ms-3 leading-tight text-sm font-medium text-black text-left py-3"
                                                       >$50</label
                                                  >
                                             </div>
                                        </li>
                                        <li class="w-full">
                                             <div class="flex items-center">
                                                  <input
                                                       id="daily-amount-b"
                                                       type="radio"
                                                       v-model="amountDailyVal"
                                                       @change="
                                                            onChangeDailyAmount
                                                       "
                                                       value="100"
                                                       name="list-amount"
                                                       class="w-4 h-4 text-background-3 focus:ring-background-3 bg-white border-shade-18"
                                                  />
                                                  <label
                                                       for="daily-amount-b"
                                                       class="w-full ms-3 leading-tight text-sm font-medium text-black text-left py-3"
                                                       >$100</label
                                                  >
                                             </div>
                                        </li>
                                        <li class="w-full">
                                             <div class="flex items-center">
                                                  <input
                                                       id="daily-amount-c"
                                                       type="radio"
                                                       v-model="amountDailyVal"
                                                       @change="
                                                            onChangeDailyAmount
                                                       "
                                                       value="1000"
                                                       name="list-amount"
                                                       class="w-4 h-4 text-background-3 focus:ring-background-3 bg-white border-shade-18"
                                                  />
                                                  <label
                                                       for="daily-amount-b"
                                                       class="w-full ms-3 leading-tight text-sm font-medium text-black text-left py-3"
                                                       >$1,000</label
                                                  >
                                             </div>
                                        </li>
                                   </ul>
                              </div>

                              <div
                                   class="flex-1 flex flex-col items-start gap-1 xs:gap-2 mt-6 xs:mt-8"
                              >
                                   <span
                                        class="font-bold text-black leading-tight text-sm xs:text-base sm:text-lg md:text-xl lg:text-xl"
                                        >Duration</span
                                   >
                                   <span
                                        class="text-shade-7 font-normal text-xs xs:text-xs sm:text-xs md:text-sm lg:text-sm"
                                        >Select duration below.</span
                                   >
                              </div>
                              <div class="w-full">
                                   <ul
                                        class="items-center w-full text-sm font-medium text-black text-left rounded-lg sm:flex gap-3"
                                   >
                                        <li class="w-full">
                                             <div class="flex items-center">
                                                  <input
                                                       checked
                                                       id="daily-duration-a"
                                                       type="radio"
                                                       v-model="
                                                            durationDailyVal
                                                       "
                                                       @change="
                                                            onChangeDailyDuration
                                                       "
                                                       value="1"
                                                       name="list-duration"
                                                       class="w-4 h-4 text-background-3 focus:ring-background-3 bg-white border-shade-18"
                                                  />
                                                  <label
                                                       for="daily-duration-a"
                                                       class="w-full ms-3 leading-tight text-sm font-medium text-black text-left py-3"
                                                       >1 day</label
                                                  >
                                             </div>
                                        </li>
                                        <li class="w-full">
                                             <div class="flex items-center">
                                                  <input
                                                       id="daily-duration-b"
                                                       type="radio"
                                                       v-model="
                                                            durationDailyVal
                                                       "
                                                       @change="
                                                            onChangeDailyDuration
                                                       "
                                                       value="7"
                                                       name="list-duration"
                                                       class="w-4 h-4 text-background-3 focus:ring-background-3 bg-white border-shade-18"
                                                  />
                                                  <label
                                                       for="daily-duration-b"
                                                       class="w-full ms-3 leading-tight text-sm font-medium text-black text-left py-3"
                                                       >7 days</label
                                                  >
                                             </div>
                                        </li>
                                        <li class="w-full">
                                             <div class="flex items-center">
                                                  <input
                                                       id="daily-duration-c"
                                                       type="radio"
                                                       v-model="
                                                            durationDailyVal
                                                       "
                                                       @change="
                                                            onChangeDailyDuration
                                                       "
                                                       value="28"
                                                       name="list-duration"
                                                       class="w-4 h-4 text-background-3 focus:ring-background-3 bg-white border-shade-18"
                                                  />
                                                  <label
                                                       for="daily-duration-c"
                                                       class="w-full ms-3 leading-tight text-sm font-medium text-black text-left py-3"
                                                       >28 days</label
                                                  >
                                             </div>
                                        </li>
                                   </ul>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     </div>
     <ExcludeReminder :confirmExclusion="confirmExclusion" />
     <ExcludeTimer />
</template>
<script>
import { ref, computed, watch, onMounted, onUnmounted } from "vue";
import {
     updateReminder as updateReminderAPI,
     transactionLimit as transactionLimitAPI,
     exclusion as exclusionAPI
} from "api/auth.js";
import { useToaster } from "composables/use-toaster.js";
import { useFormatter } from "composables/use-formatter.js";
import { authStore } from "store/auth.js";
import _ from "underscore";
import { useRouter } from "vue-router";
import eventBus from "plugins/event.js";
import ExcludeReminder from "components/modals/excludeModal.vue";
import ExcludeTimer from "components/modals/excludeTimer.vue";
import { add } from "date-fns";
import { coinsStore } from "store/coins";

export default {
     props: ["social_linked", "setSocialLink"],
     components: {
          ExcludeReminder,
          ExcludeTimer
     },
     setup() {
          const useAuthStore = authStore();
          const useCoinsStore = coinsStore();

          const { date } = useFormatter();

          const isUser = computed(() => {
               return useAuthStore.user;
          });

          const router = useRouter();

          const isCheckedReminder = ref(isUser.value.reminder ? true : false);
          const isCheckedSelfExclude = ref(false);

          const _isCheckedSelfExclude = ref(false);

          const isCheckedDailyPurchaseLimit = ref(false);
          const responsibleLoading = ref(true);

          const allowToExclude = ref(null);
          const durationExcludeVal = ref(null);

          const amountDailyVal = ref(50);
          const durationDailyVal = ref(1);
          const lastDateVal = ref(null);

          const { successToast, errorToast } = useToaster();

          const handleCheckboxChange = async (is_from) => {
               if (is_from == "reminder") {
                    responsibleLoading.value = true;
                    isCheckedReminder.value = !isCheckedReminder.value;
                    await updateReminderAPI({
                         status: isCheckedReminder.value ? 1 : 0
                    })
                         .then(() => {
                              successToast(
                                   "Successfully Updated!",
                                   "top-right"
                              );
                              responsibleLoading.value = false;
                         })
                         .catch(() => {
                              errorToast(
                                   "Error to update reminder!",
                                   "top-right"
                              );
                              responsibleLoading.value = false;
                         });
               } else if (is_from == "self-exclude") {
                    responsibleLoading.value = true;
                    if (!allowToExclude.value) {
                         responsibleLoading.value = false;
                         return;
                    }

                    if (isCheckedSelfExclude.value) {
                         if (_isCheckedSelfExclude.value) {
                              confirmExclusion(true);
                         } else {
                              toggleSelfExclude();
                         }
                    } else {
                         toggleSelfExclude();
                    }

                    responsibleLoading.value = false;
                    return;
               } else if (is_from == "daily") {
                    responsibleLoading.value = true;
                    isCheckedDailyPurchaseLimit.value =
                         !isCheckedDailyPurchaseLimit.value;
                    if (isCheckedDailyPurchaseLimit.value) {
                         await transactionLimitAPI({
                              days: durationDailyVal.value,
                              amount: amountDailyVal.value
                         })
                              .then(async () => {
                                   await useAuthStore.getUser(true);
                                   await useCoinsStore.getLimitReach(true);
                                   successToast(
                                        "Successfully Updated!",
                                        "top-right"
                                   );
                                   responsibleLoading.value = false;
                              })
                              .catch(() => {
                                   errorToast(
                                        "Error to update purchase limit!",
                                        "top-right"
                                   );
                                   responsibleLoading.value = false;
                              });
                    } else {
                         await transactionLimitAPI({
                              unset: 1
                         })
                              .then(async () => {
                                   await useAuthStore.getUser(true);
                                   await useCoinsStore.getLimitReach(true);
                                   amountDailyVal.value = 50;
                                   durationDailyVal.value = 1;
                                   successToast(
                                        "Successfully Updated!",
                                        "top-right"
                                   );
                                   responsibleLoading.value = false;
                              })
                              .catch(() => {
                                   errorToast(
                                        "Error to update purchase limit!",
                                        "top-right"
                                   );
                                   responsibleLoading.value = false;
                              });
                    }
               }
          };

          const onChangeDailyAmount = async () => {
               responsibleLoading.value = true;
               await transactionLimitAPI({
                    days: Number(durationDailyVal.value),
                    amount: Number(amountDailyVal.value)
               })
                    .then(() => {
                         successToast("Successfully Updated!", "top-right");
                         responsibleLoading.value = false;
                    })
                    .catch(() => {
                         errorToast(
                              "Error to update purchase limit!",
                              "top-right"
                         );
                         responsibleLoading.value = false;
                    });
          };

          const onChangeDailyDuration = async () => {
               responsibleLoading.value = true;
               await transactionLimitAPI({
                    days: Number(durationDailyVal.value),
                    amount: Number(amountDailyVal.value)
               })
                    .then(async () => {
                         successToast("Successfully Updated!", "top-right");
                         await useAuthStore.getUser(true);
                         responsibleLoading.value = false;
                    })
                    .catch(() => {
                         errorToast(
                              "Error to update purchase limit!",
                              "top-right"
                         );
                         responsibleLoading.value = false;
                    });
          };

          const onChangeExcludeDuration = async () => {
               openExcludeReminder();
          };

          const goToRedeem = () => {
               router.push({ path: "/redeem/" });
          };

          const openExcludeReminder = () => {
               eventBus.emit("open:excludereminder");
          };

          const openExcludeTimer = () => {
               eventBus.emit("open:excludetimer");
          };

          const toggleSelfExclude = () => {
               isCheckedSelfExclude.value = !isCheckedSelfExclude.value;
          };

          const confirmExclusion = async (unset = false) => {
               let params = {};

               if (unset) {
                    params["unset"] = 1;
                    params["days"] = 0;
               } else {
                    params["days"] = Number(durationExcludeVal.value);
               }

               responsibleLoading.value = true;
               await exclusionAPI(params)
                    .then(() => {
                         successToast("Successfully Updated!", "top-right");
                         responsibleLoading.value = false;

                         if (unset) {
                              isCheckedSelfExclude.value = false;
                              _isCheckedSelfExclude.value = false;
                              durationExcludeVal.value = null;
                         } else {
                              isCheckedSelfExclude.value = true;
                              _isCheckedSelfExclude.value = true;
                         }

                         setTimeout(() => {
                              openExcludeTimer();
                         }, 300);
                    })
                    .catch(() => {
                         errorToast("Error to update reminder!", "top-right");
                         responsibleLoading.value = false;
                    });
          };

          const checkMeta = (newVal) => {
               if (newVal) {
                    if (newVal?.wallet) {
                         let filter = _.filter(
                              newVal.wallet,
                              function (params) {
                                   return params["type"] == "silver_winnings";
                              }
                         );

                         if (filter.length >= 1) {
                              if (Math.floor(filter[0].balance) == 0) {
                                   allowToExclude.value = true;
                              } else {
                                   isCheckedSelfExclude.value = false;
                                   _isCheckedSelfExclude.value = false;
                                   allowToExclude.value = false;
                              }
                         } else {
                              allowToExclude.value = true;
                         }
                    }

                    if (newVal?.meta) {
                         let filter = _.filter(newVal.meta, function (params) {
                              return params["meta_key"] == "daily_limit";
                         });

                         if (filter.length >= 1) {
                              isCheckedDailyPurchaseLimit.value = true;
                              const parseFilter = JSON.parse(
                                   filter[0].meta_value
                              );
                              amountDailyVal.value = parseFilter.amount;
                              durationDailyVal.value = parseFilter.days;
                              lastDateVal.value = date(
                                   add(new Date(filter[0].created_at), {
                                        days: durationDailyVal.value
                                   })
                              );
                         }

                         let filterExclusion = _.filter(
                              newVal.meta,
                              function (params) {
                                   return params["meta_key"] == "exclusion";
                              }
                         );

                         if (filterExclusion.length >= 1) {
                              if (allowToExclude.value) {
                                   isCheckedSelfExclude.value = true;
                                   _isCheckedSelfExclude.value = true;
                                   const parseFilter = JSON.parse(
                                        filterExclusion[0].meta_value
                                   );
                                   durationExcludeVal.value = parseFilter.days;
                              }
                         }
                    }
               }
          };

          onMounted(() => {
               responsibleLoading.value = true;
               checkMeta(isUser.value);
               responsibleLoading.value = false;
          });

          onUnmounted(async () => {
               await useAuthStore.getUser(true);
          });

          watch(isUser, (newVal) => {
               checkMeta(newVal);
          });

          return {
               handleCheckboxChange,
               isCheckedDailyPurchaseLimit,
               isCheckedSelfExclude,
               isCheckedReminder,
               responsibleLoading,
               amountDailyVal,
               durationDailyVal,
               durationExcludeVal,
               onChangeDailyAmount,
               onChangeDailyDuration,
               onChangeExcludeDuration,
               allowToExclude,
               goToRedeem,
               openExcludeReminder,
               toggleSelfExclude,
               openExcludeTimer,
               confirmExclusion,
               lastDateVal
          };
     }
};
</script>
