import {
     paymentDetails as paymentDetailsAPI,
     getCreditCard as getCreditCardAPI,
     getCardDetails as getCardDetailsAPI
} from "api/payment.js";
import { bankStatus as bankStatusAPI } from "api/transaction.js";
import { defineStore } from "pinia";
import _ from "underscore";

export const cardStore = defineStore("cards", {
     state: () => {
          return {
               cards: null,
               cardsRedeem: null,
               bankStatus: null
          };
     },
     actions: {
          getCards(isForce = false) {
               if (this.cards != null && !isForce) {
                    return;
               }
               return new Promise((resolve, reject) => {
                    paymentDetailsAPI()
                         .then((response) => {
                              this.$patch((state) => {
                                   state.cards = response.list;
                              });
                              resolve();
                         })
                         .catch((error) => {
                              // errorToast('Error to load data!', "top-right");
                              console.error(
                                   "Error to load testimonials data!",
                                   error
                              );
                              reject(error);
                         });
               });
          },
          getCardsRedeem(isForce = false) {
               if (this.cardsRedeem != null && !isForce) {
                    return;
               }
               return new Promise((resolve, reject) => {
                    getCreditCardAPI()
                         .then((response) => {
                              this.$patch((state) => {
                                   if (response?.list?.length) {
                                        const map = _.map(
                                             response.list,
                                             function (params) {
                                                  return params.paypage
                                                       .firstSix;
                                             }
                                        );

                                        this.getCardDetails(
                                             map,
                                             response?.list
                                        );
                                   } else {
                                        state.cardsRedeem = [];
                                   }
                              });
                         })
                         .catch((error) => {
                              // errorToast('Error to load data!', "top-right");
                              console.error(
                                   "Error to load testimonials data!",
                                   error
                              );
                              reject(error);
                         });
               });
          },
          getBankStatus() {
               return new Promise((resolve, reject) => {
                    bankStatusAPI()
                         .then((response) => {
                              this.$patch((state) => {
                                   state.bankStatus = response;
                              });
                              resolve();
                         })
                         .catch((error) => {
                              // errorToast('Error to load data!', "top-right");
                              this.$patch((state) => {
                                   state.bankStatus = false;
                              });
                              console.error(
                                   "Error to load testimonials data!",
                                   error
                              );
                              reject(error);
                         });
               });
          },
          getCardDetails(params, list) {
               return new Promise((resolve, reject) => {
                    getCardDetailsAPI({ firstsix: params })
                         .then((response) => {
                              this.$patch((state) => {
                                   // visa debit = OK
                                   // mastercard debit = OK
                                   // visa credit = OK
                                   // mastercard debt it = NOT OK

                                   // condition for unacceptable card
                                   const condition = [
                                        {
                                             brand: "mastercard",
                                             type: "debit"
                                        }
                                   ];

                                   const removeCardList = _.filter(
                                        response,
                                        function (params) {
                                             for (
                                                  let index = 0;
                                                  index < condition.length;
                                                  index++
                                             ) {
                                                  if (
                                                       params[
                                                            "brand"
                                                       ].toLowerCase() ==
                                                            condition[index][
                                                                 "brand"
                                                            ] &&
                                                       params[
                                                            "type"
                                                       ].toLowerCase() ==
                                                            condition[index][
                                                                 "type"
                                                            ]
                                                  ) {
                                                       return params.bin;
                                                  }
                                             }
                                        }
                                   );

                                   if (removeCardList.length != 0) {
                                        const pluck = _.pluck(
                                             removeCardList,
                                             "bin"
                                        );

                                        const filterList = _.map(
                                             list,
                                             function (params) {
                                                  params.paypage[
                                                       "isRestricted"
                                                  ] = pluck.includes(
                                                       params.paypage.firstSix
                                                  );
                                                  return params;
                                             }
                                        );

                                        state.cardsRedeem = filterList;
                                   } else {
                                        state.cardsRedeem = list;
                                   }
                              });
                              resolve();
                         })
                         .catch((error) => {
                              // errorToast('Error to load data!', "top-right");
                              this.$patch((state) => {
                                   state.bankStatus = false;
                              });
                              console.error(
                                   "Error to load testimonials data!",
                                   error
                              );
                              reject(error);
                         });
               });
          }
     }
});
